
import Vue from "vue";
import TiposImporteList from "@/components/subviews/tipos-importe/tipos-importe-list.vue";
import TiposImporteMaintenance from "@/components/subviews/tipos-importe/tipos-importe-maintenance.vue";
import ITipoImporte from "@/lib/interfaces/tipo-importe";

export default Vue.extend({
  name: "TiposImporte",

  components: { TiposImporteList, TiposImporteMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      tipoImporte: {} as ITipoImporte,
    };
  },
});
